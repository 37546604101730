import { useState } from "react";
import { LoginSuccess } from "./LoginSuccess";
import { LoginForm } from "./LoginForm";

export const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  if (isLoggedIn) {
    return <LoginSuccess />;
  } else {
    return <LoginForm onLogin={() => setIsLoggedIn(true)} />;
  }
};

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { helloWorld, login } from "../../API/UserApi";
import { StyledButton } from "../../Components/StyledButton";
import { StyledLabel } from "../../Components/StyledLabel";
import photo from "../../Assets/pexels-vlada-karpovich-4617316.jpg";
import { ButlrLogo } from "../../Components/ButlerLogo";
import { InputWithFeedback } from "../../Components/InputWithFeedback";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address"),
  password: yup.string().required("Please enter your password"),
});

export const LoginForm = (props: { onLogin: () => void }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async () => {
    const success = await login(email, password, rememberMe);
    if (success) {
      props.onLogin();
    } else {
      // error message
    }
  };

  const validateForm = () => {
    try {
      schema.fields.email.validateSync(email);
      schema.fields.password.validateSync(password);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    helloWorld();
  }, []);

  return (
    <Row className="align-items-center gx-0 overflow-hidden">
      <Col xs={12} md={6}>
        <ButlrLogo />
        <Row className="justify-content-center">
          <Col xs={10} md={6}>
            <Row>
              <h3 className="text-center">
                <strong>Sign In</strong>
              </h3>
            </Row>
            <Row>
              <p className="text-center text-color-2">
                Sign in to stay connected
              </p>
            </Row>
            <Form>
              <FormGroup row>
                <StyledLabel>Email</StyledLabel>
                <InputWithFeedback
                  yupschema={schema.fields.email}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup row>
                <StyledLabel>Password</StyledLabel>
                <InputWithFeedback
                  yupschema={schema.fields.password}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup inline check>
                <Input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                ></Input>
                <StyledLabel check>Remember me?</StyledLabel>
                {/* <Link to="/">Forgot password</Link> */}
              </FormGroup>
              <Row className="my-3 justify-content-center">
                <StyledButton onClick={handleSubmit} disabled={!validateForm()}>
                  Sign In
                </StyledButton>
              </Row>
              <Row className="text-center">
                <span>
                  <p className="d-inline">Don't have an account? </p>
                  <Link to="/createaccount">Click here to sign up.</Link>
                </span>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col md={6} className="d-none d-md-block">
        <img
          src={photo}
          alt="stock image of person holding phone and listening to earbuds"
          className="img-fluid"
        />
      </Col>
    </Row>
  );
};

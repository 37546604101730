import { default as axios } from "axios";
import { apiUrl } from "./ApiBase";
import { addDays } from "date-fns";
import { User } from "../Models/User";

axios.defaults.withCredentials = true;

export const createUser = async (user: unknown) => {
  await axios.post(`${apiUrl}/user/create`, user);
};

export const login = async (
  email: string,
  password: string,
  rememberMe: boolean
): Promise<boolean> => {
  try {
    await axios.post(`${apiUrl}/user/login`, {
      email,
      password,
      rememberMe,
    });

    document.cookie = `isLoggedIn=true; expires=${
      rememberMe ? addDays(new Date(), 7) : ""
    }; secure; sameSite=strict`;
    return true;
  } catch (error) {
    return false;
  }
};

export const logout = async () => {
  await axios.post(`${apiUrl}/user/logout`);
  document.cookie = `isLoggedIn=;expires=${new Date(1)}`;
};

export const getUserInfo = async (): Promise<User> => {
  const response = await axios.get(`${apiUrl}/user/current`);
  return response.data;
};

export const helloWorld = async () => {
  axios.get(`${apiUrl}/helloworld`);
};

import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { CreateAccount } from "./Pages/CreateAccount/CreateAccount";
import { Login } from "./Pages/Login/Login";
import "./Assets/custom_bootstrap.scss";
import { RequestService } from "./Pages/RequestService/RequestService";
import { Paths } from "./Paths";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.Login} element={<Login />} />
        <Route path={Paths.CreateAccount} element={<CreateAccount />} />
        <Route
          path={Paths.Request}
          element={
            <PrivateRoute>
              <RequestService />
            </PrivateRoute>
          }
        />
        {/* <Route path={"/viewrequests"} element={<ViewRequests />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

const PrivateRoute = (props: { children: JSX.Element }) => {
  const cookieIndex = document.cookie.indexOf("isLoggedIn=");
  let location = useLocation();

  if (cookieIndex < 0) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.children;
};

export default App;

import { default as axios } from "axios";
import { CallRequest } from "../Models/CallRequest";
import { Service } from "../Models/Service";
import { ServiceTypeLib } from "../Models/ServiceTypeLib";
import { apiUrl } from "./ApiBase";

axios.defaults.withCredentials = true;

export const getServiceTypes = async (): Promise<ServiceTypeLib[]> => {
  return (await axios.get(`${apiUrl}/service/serviceTypes`)).data;
};

export const createService = async (service: Service) => {
  return axios.post(`${apiUrl}/service`, service);
};

export const getServicesByUserId = async (): Promise<Service[]> => {
  return (await axios.get(`${apiUrl}/service`)).data;
};

export const requestCall = async (request: CallRequest): Promise<boolean> => {
  const response = await axios.post(`${apiUrl}/service/requestCall`, request);
  return response.status === 202;
}

import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import photo from "../../Assets/pexels-sebastian-voortman-214574.jpg";
import { StyledButton } from "../../Components/StyledButton";
import checkmark from "../../Assets/checkmark.svg";
import { Paths } from "../../Paths";
import { ButlrLogo } from "../../Components/ButlerLogo";

export const LoginSuccess = () => {
  const nav = useNavigate();

  return (
    <Row className="align-items-center gx-0 overflow-hidden">
      <Col xs={12} md={6}>
        <ButlrLogo />
        <Row className="justify-content-center">
          <Col xs={10} md={6} className="gy-2">
            <Row className="justify-content-center">
              <img src={checkmark} height={86} alt="People wearing headsets" />
            </Row>
            <Row className="my-3">
              <h1 className="xl-heading text-center text-primary-dark">
                <strong>Success!</strong>
              </h1>
            </Row>
            <Row>
              <p className="text-center text-color-1">
                You're logged in, click below to create a service request.
              </p>
            </Row>
            <Row className="justify-content-center my-4">
              <StyledButton
                color="primary-very-dark styled-button"
                onClick={async () => {
                  nav(Paths.Request);
                }}
              >
                Request
              </StyledButton>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md={6} className="d-none d-md-block align-items-center">
        <img
          src={photo}
          alt={"Some people wearing headsets"}
          className="img-fluid"
        />
      </Col>
    </Row>
  );
};

import { useState } from "react";
import { requestCall } from "../../API/ServicesApi";
import { CallRequest } from "../../Models/CallRequest";
import { RequestServiceForm } from "./RequestServiceForm";
import { RequestSuccess } from "./RequestSuccess";

export const RequestService = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = (request: CallRequest) => {
    (async () => {
      const success = await requestCall(request);
      if (success) {
        setIsSuccess(true)
      }
    })()
  }

  return isSuccess ? (
    <RequestSuccess onRequestAnother={() => setIsSuccess(false)} />
  ) : (
    <RequestServiceForm onSubmit={handleSubmit} />
  );
};

import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { getUserInfo } from "../../API/UserApi";
import photo from "../../Assets/pexels-mart-production-7415035.jpg";
import { ButlrLogo } from "../../Components/ButlerLogo";
import { InputWithFeedback } from "../../Components/InputWithFeedback";
import { StyledButton } from "../../Components/StyledButton";
import { StyledLabel } from "../../Components/StyledLabel";
import * as yup from "yup";
import { CallRequest } from "../../Models/CallRequest";

const schema = yup.object().shape({
  phoneNumber: yup
    .number()
    .required("Please enter your phone number")
    .typeError("Please enter your phone number")
    .test(
      "PhoneNumberLength",
      "Phone number must be 10 digits",
      (val) => val?.toString().length === 10
    ),
  servicesNeeded: yup
    .string()
    .required("Please tell us what services you need"),
  tosChecked: yup.bool().isTrue(),
});

export const RequestServiceForm = (props: { onSubmit: (callRequest: CallRequest) => void }) => {
  const [phoneNumber, setPhoneNumber] = useState<number | string>("");
  const [servicesNeeded, setServicesNeeded] = useState("");
  const [tosChecked, setTosChecked] = useState(false);

  useEffect(() => {
    (async () => {
      const user = await getUserInfo();
      setPhoneNumber(user.phone);
    })();
  }, []);

  const validateForm = () => {
    try {
      schema.fields.phoneNumber.validateSync(phoneNumber);
      schema.fields.servicesNeeded.validateSync(servicesNeeded);
      schema.fields.tosChecked.validateSync(tosChecked);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Row className="align-items-center gx-0 overflow-hidden">
      <Col md={6} className="d-none d-md-block">
        <img
          src={photo}
          className="img-fluid"
          alt="Family sitting by some moving boxes"
        />
      </Col>
      <Col xs={12} md={6}>
        <ButlrLogo />
        <Row className="justify-content-center">
          <Col xs={10} md={6}>
            <Row className="mt-2">
              <h3 className="text-center">
                <strong>Request a call</strong>
              </h3>
            </Row>
            <Row className="mb-2">
              <h3 className="text-center">
                <strong>From your personal Butlr</strong>
              </h3>
            </Row>
            <Row>
              <p className="text-center text-color-2">
                Tell us what services you need, click 'call me', and we'll do
                the rest!
              </p>
            </Row>
            <Form>
              <FormGroup row>
                <StyledLabel>Services Needed:</StyledLabel>
                <InputWithFeedback
                  yupschema={schema.fields.servicesNeeded}
                  type="textarea"
                  bsSize="lg"
                  value={servicesNeeded}
                  onChange={(e) => setServicesNeeded(e.target.value)}
                />
              </FormGroup>
              <FormGroup row>
                <StyledLabel>Call me at:</StyledLabel>
                <InputWithFeedback
                  yupschema={schema.fields.phoneNumber}
                  maxLength={10}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormGroup>
              <FormGroup check>
                <Input
                  yupschema={schema.fields.tosChecked}
                  type="checkbox"
                  rows={10}
                  checked={tosChecked}
                  onChange={() => setTosChecked(!tosChecked)}
                />
                <StyledLabel check>I agree with the terms of use</StyledLabel>
              </FormGroup>
              <Row className="justify-content-center">
                <StyledButton
                  disabled={!validateForm()}
                  onClick={() => props.onSubmit({servicesNeeded, phoneNumber: phoneNumber.toString()})}
                >
                  Call me
                </StyledButton>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { createUser, login } from "../../API/UserApi";
import { Address } from "../../Models/Address";
import { CreateUser } from "../../Models/User";
import photo from "../../Assets/pexels-mart-production-7415035.jpg";
import { ButlrLogo } from "../../Components/ButlerLogo";
import { StyledLabel } from "../../Components/StyledLabel";
import { StyledButton } from "../../Components/StyledButton";
import * as yup from "yup";
import { InputWithFeedback } from "../../Components/InputWithFeedback";
import { Paths } from "../../Paths";

const formInitialValues: CreateUser = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
  },
  tosChecked: false,
};

const schema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address"),
  phone: yup
    .number()
    .required("Please enter your phone number")
    .typeError("Please enter your phone number")
    .test(
      "PhoneNumberLength",
      "Phone number must be 10 digits",
      (val) => val?.toString().length === 10
    ),
  password: yup.string().required("Please enter a password"),
  confirmPassword: yup.string().required("Please confirm the password"),
  address: yup.object().shape({
    addressLine1: yup.string().required("Please enter your address"),
    addressLine2: yup.string().notRequired(),
    city: yup.string().required("Please enter the city"),
    state: yup.string().required("Please enter the state"),
    zip: yup
      .number()
      .required("Please enter the zip code")
      .typeError("Please enter the zip code")
      .test(
        "ZipCodeLength",
        "Zip code must be 5 or 9 digits",
        (val) => val?.toString().length === 5 || val?.toString().length === 9
      ),
  }),
  tosChecked: yup.bool().isTrue(),
});

export const CreateAccount = () => {
  const [form, setForm] = useState<CreateUser>(formInitialValues);
  const nav = useNavigate();

  const handleUpdate = (
    event: ChangeEvent<HTMLInputElement>,
    key: keyof CreateUser
  ) => {
    const value = event.target.value;
    setForm({ ...form, [key]: value });
  };

  const handleAddressUpdate = (
    event: ChangeEvent<HTMLInputElement>,
    key: keyof Address
  ) => {
    const value = event.target.value;
    setForm({ ...form, address: { ...form.address, [key]: value } });
  };

  const validateForm = () => {
    try {
      schema.validateSync(form);
      return true;
    } catch {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      await createUser(form);
      await login(form.email, form.password, false);
      nav(Paths.Request);
    }
  };

  return (
    <Row className="align-items-center gx-0 overflow-hidden">
      <Col md={6} className="d-none d-md-block align-items-center">
        <img
          src={photo}
          alt="stock image of person holding phone and listening to earbuds"
          className="img-fluid"
        />
      </Col>
      <Col xs={12} md={6}>
        <ButlrLogo />
        <Row className="justify-content-center">
          <Col xs={10} md={6}>
            <Row className="justify-content-center">
              <h3 className="text-center">
                <strong>Sign Up</strong>
              </h3>
            </Row>
            <Row>
              <p className="text-center text-color-2">
                Create your Butlr account
              </p>
            </Row>
            <Form>
              <FormGroup row>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>First Name</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.firstName}
                    value={form?.firstName}
                    onChange={(e) => handleUpdate(e, "firstName")}
                  />
                </Col>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Last Name</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.lastName}
                    value={form?.lastName}
                    onChange={(e) => handleUpdate(e, "lastName")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Email</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.email}
                    type="email"
                    value={form?.email}
                    onChange={(e) => handleUpdate(e, "email")}
                  />
                </Col>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Phone</StyledLabel>
                  <InputWithFeedback
                    maxLength={10}
                    yupschema={schema.fields.phone}
                    value={form?.phone}
                    onChange={(e) => handleUpdate(e, "phone")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Password</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.password}
                    type="password"
                    value={form?.password}
                    onChange={(e) => handleUpdate(e, "password")}
                  />
                </Col>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Confirm Password</StyledLabel>
                  <InputWithFeedback
                    yupschema={
                      // Crazy hack, but I can't for the life of me figure out how to compare two fields with Yup
                      // so we're just checking to see if the passwords match here, if they do we just use the schema from
                      // above, if they don't then we pass in a schema that is a guaranteed fail
                      form?.password === form?.confirmPassword
                        ? schema.fields.confirmPassword
                        : yup
                            .object()
                            .shape({
                              fail: yup.string(),
                            })
                            .typeError("Passwords must match")
                    }
                    type="password"
                    value={form?.confirmPassword}
                    onChange={(e) => handleUpdate(e, "confirmPassword")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Address Line 1</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.address.fields.addressLine1}
                    value={form?.address.addressLine1}
                    onChange={(e) => handleAddressUpdate(e, "addressLine1")}
                  />
                </Col>
                <Col xs={12} sm={6} md={12} xl={6}>
                  <StyledLabel>Address Line 2</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.address.fields.addressLine2}
                    value={form?.address.addressLine2}
                    onChange={(e) => handleAddressUpdate(e, "addressLine2")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} sm={4} md={12} xl={4}>
                  <StyledLabel>City</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.address.fields.city}
                    value={form?.address.city}
                    onChange={(e) => handleAddressUpdate(e, "city")}
                  />
                </Col>
                <Col xs={12} sm={4} md={12} xl={4}>
                  <StyledLabel>State</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.address.fields.state}
                    maxLength={2}
                    value={form?.address.state}
                    onChange={(e) => handleAddressUpdate(e, "state")}
                  />
                </Col>
                <Col xs={12} sm={4} md={12} xl={4}>
                  <StyledLabel>Zip</StyledLabel>
                  <InputWithFeedback
                    yupschema={schema.fields.address.fields.zip}
                    maxLength={9}
                    value={form?.address.zip}
                    onChange={(e) => handleAddressUpdate(e, "zip")}
                  />
                </Col>
              </FormGroup>
              <Row>
                <FormGroup check className="d-flex justify-content-center">
                  <Input
                    className="mx-1 border-primary"
                    type="checkbox"
                    checked={form.tosChecked}
                    onChange={() => {
                      setForm({ ...form, tosChecked: !form.tosChecked });
                    }}
                  />
                  <StyledLabel check>I agree with the terms of use</StyledLabel>
                </FormGroup>
              </Row>
              <Row className="justify-content-center my-3">
                <StyledButton onClick={handleSubmit} disabled={!validateForm()}>
                  Sign up
                </StyledButton>
              </Row>
            </Form>
            <Row className="text-center">
              <span>
                <p className="d-inline">Already have an account? </p>
                <Link to="/login">Sign In</Link>
              </span>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

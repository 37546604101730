import { useEffect, useState } from "react";
import { FormFeedback, Input, InputProps } from "reactstrap";
import * as yup from "yup";

interface InputWithFeedbackProps extends InputProps {
  yupschema: yup.AnySchema;
}

export const InputWithFeedback = (props: InputWithFeedbackProps) => {
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState<yup.ValidationError | null>(
    null
  );

  useEffect(() => {
    try {
      props.yupschema.validateSync(props.value);
      setErrorMessage(null);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setErrorMessage(error);
      }
    }
  }, [props.yupschema, props.value]);

  return (
    <>
      <Input
        invalid={!!errorMessage && touched}
        onBlur={() => setTouched(true)}
        className={errorMessage && touched ? undefined : "border-primary"}
        {...props}
      />
      <FormFeedback>{errorMessage?.message}</FormFeedback>
    </>
  );
};
